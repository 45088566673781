<template>
  <div class="flex-container" :class="{ 'full-width-items': fullWidthItems }">
    <slot />
  </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  props: {
    fullWidthItems: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  max-width: 100%;

  &.full-width-items {
    & > div {
      width: 100%;
    }
  }

  &.spaced {
    & > div {
      margin-right: 18px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &.responsive {
    display: block;

    @media only screen and (min-width: 800px) {
      display: flex;
    }
  }

  @media only screen and (min-width: 71.313em) {
    width: 1140px;
  }

  @media only screen and (min-width: 82.563em) {
    width: 1300px;
  }
}
</style>
