import Vue from "vue";

declare global {
  interface Window {
    FB: any;
  }
}

export default Vue.directive("facebook-signin-button", {
  bind: function (el, binding, vnode) {
    const { value } = binding;

    if (!value) {
      return;
    }

    checkComponentMethods();
    const appId = value;
    const facebookSignInAPI = document.createElement("script");
    facebookSignInAPI.setAttribute(
      "src",
      "//connect.facebook.net/en_US/sdk.js",
    );
    document.head.appendChild(facebookSignInAPI);

    facebookSignInAPI.onload = InitFacebookButton;

    function InitFacebookButton() {
      window.FB.init({
        appId,
        xfbml: true,
        version: "v9.0",
      });
      // FB.logout()
    }

    el.addEventListener("click", PopUp);

    function PopUp() {
      window.FB.login(
        function (facebookResponse) {
          if (facebookResponse.status === "connected") {
            window.FB.api("/me?fields=name,email", function (response) {
              const payload = {
                authResponse: facebookResponse.authResponse,
                basicProfile: response,
              };
              onSuccess(payload);
            });
          } else {
            onFail();
          }
        },
        { scope: "public_profile,email", return_scopes: true },
      );
    }
    function onSuccess(payload) {
      vnode.context.onFacebookAuthSuccess(payload);
    }
    function onFail() {
      vnode.context.onFacebookAuthFail("facebook_signin_failed");
    }
    function checkComponentMethods() {
      if (!vnode.context.onFacebookAuthSuccess) {
        throw new Error(
          "The method onFacebookAuthSuccess must be defined on the component",
        );
      }

      if (!vnode.context.onFacebookAuthFail) {
        throw new Error(
          "The method onFacebookAuthFail must be defined on the component",
        );
      }
    }
  },
});
