const PHONE_REGEX = {
  ID_PHONE_NUMBER: /^(0|\+?62)8\d{8,11}$/,
  MY_PHONE_NUMBER: /^\+60\d{8,10}$/,
  SG_PHONE_NUMBER: /^\+65\d{8}$/,
  BN_PHONE_NUMBER: /^\+673\d{7,10}$/,
  TW_PHONE_NUMBER: /^\+886\d{5,9}$/,
  HK_PHONE_NUMBER: /^\+852\d{3,11}$/,
  CN_PHONE_NUMBER: /^\+86\d{11}$/,
};

const EMAIL_REGEX = /^([\w+\-].?)+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]+$/i;

export const isValidPhoneNumber = (phoneNumber) => {
  return Object.values(PHONE_REGEX).some((id) => id.test(phoneNumber));
};

export const isValidEmail = (email) => {
  return EMAIL_REGEX.test(email);
};

export const handleType = (handle) => {
  if (isValidPhoneNumber(handle)) {
    return "phone";
  } else if (isValidEmail(handle)) {
    return "email";
  } else {
    return "username";
  }
};
