<template>
  <div data-ref="login-form-hidden-inputs">
    <input type="hidden" :name="csrf.param" :value="csrf.token" />
    <input type="hidden" name="comeback" :value="comebackUrl" />
  </div>
</template>

<script lang="ts">
import { csrf } from "@/utils/csrf";
import Vue from "vue";
// @ts-ignore
import { generateComebackUrl } from "@/utils/url";

export default Vue.extend({
  data() {
    return {
      csrf,
    };
  },
  computed: {
    comebackUrl() {
      return generateComebackUrl(global.location.href);
    },
  },
});
</script>

<style></style>
