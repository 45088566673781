<template>
  <img data-test-ref="image" :src="actualSrc" :alt="alt" />
</template>

<script lang="ts">
import Vue from "vue";
import { imagePath } from "@/utils/image_path";
import { checkWebpSupport } from "@/utils/check_webp_support";

export default Vue.extend({
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      supportWebp: checkWebpSupport(),
    };
  },
  computed: {
    hasExtention() {
      return /\.(.{3,4})$/.test(this.src);
    },
    isWebpSrc() {
      return this.src.endsWith(".webp");
    },
    actualSrc() {
      const { hasExtention, isWebpSrc, supportWebp } = this;
      let { src } = this;

      if (!hasExtention) {
        src = `${src}.jpg`;
      }

      if (supportWebp && !isWebpSrc) {
        return imagePath(`${src}.webp`);
      } else if (!supportWebp && isWebpSrc) {
        return imagePath(src.replace(/.webp$/, ""));
      }

      return imagePath(src);
    },
  },
});
</script>
