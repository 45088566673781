export default (url, host = "") => {
  const key = "comeback";
  const regex = new RegExp("[?&]" + key + "(=([^&#]*)|&|#|$)");
  const results = regex.exec(url);
  let isUrl;
  try {
    isUrl = !!new URL(decodeURIComponent(results[2]));
  } catch (err) {
    isUrl = false;
  }
  if (!results) return undefined;
  if (!results[2]) return "";
  return (
    (!isUrl ? host.replace(/\/*$/, "") : "") +
    decodeURIComponent(results[2].replace(/\+/g, " "))
  );
};
