import Vue from "vue";
import Cookies from "js-cookie";
import ImageWrapper from "@/components/atoms/ImageWrapper/Main.vue";
// @ts-ignore
import FlexContainer from "@/components/atoms/FlexContainer/Main.vue";
import { serialize } from "@/utils/search_params";
import { generateComebackUrl } from "@/utils/url";
import { OtpFormModal } from "./dynamics";
import GoogleSignInButton from "@/directives/google-sign-in";
import FacebookSignInButton from "@/directives/facebook-sign-in";
import HiddenInputs from "./HiddenInputs.vue";
import { csrf } from "@/utils/csrf";
import { generateFormDataFromObject } from "@/utils/form_data";
import { isValidPhoneNumber, isValidEmail, handleType } from "@/utils/regex";
import { mockUserSessionRequest } from "@/test/mocks/user_session_request";

export default Vue.extend({
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: true,
    },
    isSocmedLoginHidden: {
      type: Boolean,
      default: false,
    },
    usernamePlaceholder: {
      type: String,
      default: "E-mail / Username / Nomor Handphone",
    },
    appendRegisterUrl: {
      type: String,
      default: "",
    },
  },
  components: {
    ImageWrapper,
    FlexContainer,
    OtpFormModal,
    HiddenInputs,
  },
  directives: {
    GoogleSignInButton,
    FacebookSignInButton,
  },
  data() {
    return {
      error: undefined,
      csrf,
      rememberMe: true,
      username: undefined,
      password: undefined,
      passwordResetUrl: this["$accounts"].appLogin.passwordResetUrl,
      requestEmailResendUrl: this["$accounts"].appLogin.requestEmailResendUrl,
      registerUrl: `${this["$accounts"].appLogin.registerUrl}${this.appendRegisterUrl}`,
      otpBackendUrl: undefined,
      otpFormData: undefined,
      showOtpFormModal: false,
      otpKey: Cookies.get("otp_key") || "otp",
      userPhone: "",
    };
  },

  computed: {
    comebackUrl() {
      return generateComebackUrl(global.location.href);
    },

    registerHereUrlTop() {
      const queryParamsObject = {
        from: "agentregister",
        comeback: this.comebackUrl,
      };
      return `${this.registerUrl}?${serialize(queryParamsObject)}`;
    },

    registerHereUrlBottomLeft() {
      const queryParamsObject = {
        from: "leftlogin",
        comeback: this.comebackUrl,
      };
      return `${this.registerUrl}?${serialize(queryParamsObject)}`;
    },

    formData() {
      const data = {
        [this.csrf.param]: this.csrf.token,
        comeback: this.comebackUrl,
        "user_session[username]": this.username,
        "user_session[password]": this.password,
        "user_session[remember_me]": this.rememberMe,
      };

      return generateFormDataFromObject(data);
    },
  },

  mounted() {
    this.setInitUsername();
  },

  methods: {
    setInitUsername() {
      const params = new URLSearchParams(window.location.search);
      const username = params.get("username");

      if (isValidPhoneNumber(username) || isValidEmail(username)) {
        this.username = username;
      }
    },

    generateFacebookLoginFormData({ authResponse, _basicProfile }) {
      const form = {
        [this.csrf.param]: this.csrf.token,
        comeback: this.comebackUrl,
        uid: authResponse.userID,
        token: authResponse.accessToken,
      };

      return generateFormDataFromObject(form);
    },

    generateGoogleLoginFormData({ idToken, email }) {
      const form = {
        [this.csrf.param]: this.csrf.token,
        comeback: this.comebackUrl,
        email: email,
        token: idToken,
        remember_me: this.rememberMe,
      };

      return generateFormDataFromObject(form);
    },

    async onFacebookAuthSuccess(payload) {
      await this.onSocialAuthSuccess({
        path: "/fb_login",
        credentials: "same-origin",
        body: this.generateFacebookLoginFormData(payload),
      });
    },

    async onGoogleAuthSuccess({ idToken, email }) {
      await this.onSocialAuthSuccess({
        path: "/google_login",
        credentials: "same-origin",
        body: this.generateGoogleLoginFormData({ idToken, email }),
      });
    },

    async onSocialAuthSuccess({ path, body }) {
      try {
        const res = await fetch(path, {
          method: "post",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Bukalapak-Identity": this.$accounts.user.tfa_identity,
          },
          body,
        });

        await this.handleAuthResponse(path, body, res);
      } catch (e) {
        this.error = {
          message: "Terjadi kesalahan",
        };
      }
    },

    onFacebookAuthFail() {
      this.error = {
        message: "Terjadi kesalahan",
      };
    },

    onGoogleAuthFail() {
      this.error = {
        message: "Terjadi kesalahan",
      };
    },

    async submit() {
      const formData = this.formData;
      try {
        const backendUrl = "/user_sessions";
        this.error = undefined;

        // const res = await mockUserSessionRequest()

        const res = await fetch(backendUrl, {
          method: "post",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Bukalapak-Identity": this.$accounts.user.tfa_identity,
          },
          credentials: "same-origin",
          body: formData,
        });

        await this.handleAuthResponse(backendUrl, formData, res);
      } catch (e) {
        this.trackLogin(formData, false);
        this.error = {
          message: "Terjadi kesalahan",
        };
      }
    },

    async handleAuthResponse(backendUrl, formData, res) {
      const code = res.status;
      const data = await res.json();
      const { errors, meta, redirect_to: redirectTo } = data;
      const firstError = errors ? errors[0] : undefined;

      if (redirectTo) {
        this.trackLogin(formData);
        return (window.location.href = redirectTo);
      }

      if (firstError) {
        this.error = {
          message: firstError.message,
        };
      } else {
        this.error = {
          message: "Terjadi kesalahan",
        };
      }

      const statusCodeActionMap = {
        401: () => {
          if (firstError) {
            if (firstError.code === 10107) {
              this.trackLogin(formData);
              this.userPhone = meta ? meta.user_phone : undefined;
              this.otpBackendUrl = backendUrl;
              this.otpFormData = formData;
              this.showOtpFormModal = true;
            } else {
              this.trackLogin(formData, false);
            }
          }
        },
      };

      const statusAction =
        statusCodeActionMap[code] || function defaultAction() {};

      statusAction();
    },

    trackLogin(formData, isSuccess = true) {
      this.$eventTracker({
        evn: this.$eventNames.login,
        login_method: "standard",
        status: isSuccess ? "003" : "004",
        input: handleType(formData.get("user_session[username]")),
      });
    },
  },
});
