import Vue from "vue";

declare global {
  interface Window {
    google: any;
  }
}

// the purpose of creating this is because the new library cannot use the custom button. so  we hide the google button that provided by google
const createFakeGoogleWrapper = () => {
  const googleLoginWrapper = document.createElement("div");
  googleLoginWrapper.style.display = "none";
  googleLoginWrapper.classList.add("custom-google-button");

  document.body.appendChild(googleLoginWrapper);

  window.google.accounts.id.renderButton(googleLoginWrapper, {
    type: "icon",
    width: 200,
  });

  const googleLoginWrapperButton: HTMLElement =
    googleLoginWrapper.querySelector("div[role=button]");

  return {
    click: () => {
      googleLoginWrapperButton.click();
    },
  };
};

function decodeCredential(credential: string) {
  const base64Url = credential.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join(""),
  );
  const decodedToken = JSON.parse(jsonPayload);
  return {
    email: decodedToken.email,
    email_verified: decodedToken.email_verified,
    hd: decodedToken.hd,
    family_name: decodedToken.family_name,
    given_name: decodedToken.given_name,
    name: decodedToken.name,
    picture: decodedToken.picture,
    id: decodedToken.sub,
  };
}

export default Vue.directive("google-signin-button", {
  bind: function (el, binding, vnode) {
    const { value } = binding;

    if (!value) {
      return;
    }

    CheckComponentMethods();
    let clientId = binding.value;

    const scriptTag = document.createElement("script");
    scriptTag.src = "https://accounts.google.com/gsi/client";
    scriptTag.async = true;
    scriptTag.defer = true;

    document.head.appendChild(scriptTag);

    scriptTag.onload = initGoogleButton;

    function initGoogleButton() {
      window.google?.accounts.id.initialize({
        client_id: clientId,
        callback: (credentialResponse) => {
          if (!credentialResponse.clientId || !credentialResponse.credential) {
            Onfail({ error: credentialResponse });
            return;
          }

          OnSuccess(credentialResponse);
        },
      });

      const fakeGoogleWrapper = createFakeGoogleWrapper();
      el.addEventListener("click", () => {
        fakeGoogleWrapper.click();
      });
    }

    function OnSuccess(credentialResponse) {
      const { credential } = credentialResponse;
      const decodedCredential = decodeCredential(credential);
      const payload = {
        idToken: credential,
        name: decodedCredential.name,
        email: decodedCredential.email,
        imageUrl: decodedCredential.picture,
      };

      vnode.context.onGoogleAuthSuccess(payload);
    }

    function Onfail({ error }) {
      vnode.context.onGoogleAuthFail(error);
    }

    function CheckComponentMethods() {
      if (!vnode.context.onGoogleAuthSuccess) {
        throw new Error(
          "The method OnGoogleAuthSuccess must be defined on the component",
        );
      }

      if (!vnode.context.onGoogleAuthFail) {
        throw new Error(
          "The method OnGoogleAuthFail must be defined on the component",
        );
      }
    }
  },
});
